/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import ArticleGrid from "~components/ArticleGrid";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import Newsletter from "~components/Newsletter";
import SEO from "~components/SEO";

const BlogPage = ({ data, location }) => {
  const { activeCategory } = useContext(AppContext);

  const [category, setCategory] = useState(null);

  //

  useEffect(() => {
    if (!activeCategory) {
      setCategory(null);
      return;
    }

    if (category !== activeCategory) {
      setCategory(activeCategory);
    }
  }, [activeCategory]);

  //

  const { markdownRemark, allMarkdownRemark } = data;
  const { frontmatter } = markdownRemark;

  const articles = [];

  let featuredArticle;

  allMarkdownRemark.edges.forEach(({ node }) => {
    if (category) {
      if (
        node?.frontmatter?.category.toLowerCase() === category.toLowerCase()
      ) {
        articles.push(node);
      }

      return;
    }

    if (!featuredArticle && node?.frontmatter?.featured) {
      featuredArticle = node;
    } else {
      articles.push(node);
    }
  });

  if (articles?.[0]) {
    articles.sort((a, b) => {
      const priorityA = a.frontmatter.priority;
      const priorityB = b.frontmatter.priority;

      if (priorityA < priorityB) {
        return -1;
      }

      if (priorityA > priorityB) {
        return 1;
      }

      return 0;
    });
  }

  if (!category) {
    if (!featuredArticle && articles?.[0]) {
      [featuredArticle] = articles;
      articles.splice(0, 1);
    }
  }

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="blog-page w-full relative">
        <nav className="blog-page__filters w-full fixed top-0 right-0 left-0 z-20 bg-white border-b-black">
          <div className="grid">
            <ul className="grid-end-12 relative flex pt-4 pb-4">
              <li className="mt-1 mr-5 b1">
                <button
                  type="button"
                  className={`${category === `Tea School` ? `underline` : ``}`}
                  onClick={() => setCategory(`Tea School`)}
                >
                  Tea School
                </button>
              </li>

              <li className="mt-1 mr-5 b1">
                <button
                  type="button"
                  className={`${category === `Lifestyle` ? `underline` : ``}`}
                  onClick={() => setCategory(`Lifestyle`)}
                >
                  Lifestyle
                </button>
              </li>

              <li className="mt-1 mr-5 b1">
                <button
                  type="button"
                  className={`${category === null ? `underline` : ``}`}
                  onClick={() => setCategory(null)}
                >
                  All Stories
                </button>
              </li>
            </ul>
          </div>
        </nav>

        {(category && (
          <section className="blog-page__banner grid">
            <h1 className="animation-appear-down grid-end-12 mt-20 xs:mt-12 f3">
              {category}
            </h1>
          </section>
        )) || (
          <section className="blog-page__banner w-full relative border-b-black">
            <div className="grid pb-12">
              <div className="blog-page__banner__content grid-end-6 xs:grid-end-12 relative text-left">
                <h1 className="animation-appear-down w-full relative mt-20 xs:mt-12 f3">
                  {frontmatter.heading}
                </h1>

                {featuredArticle && (
                  <article className="animation-appear-left animation-delay-1 mt-12 xs:mt-6 pt-1">
                    <h2 className="w-3/4 xs:w-full f3">
                      {featuredArticle.frontmatter.title}
                    </h2>

                    <p className="w-3/4 xs:w-full mt-4 b1">
                      {featuredArticle.frontmatter.tagline}
                    </p>

                    <Link
                      to={featuredArticle.fields.slug}
                      className="inline-block mt-8 b1 underline"
                    >
                      Read story
                    </Link>
                  </article>
                )}
              </div>

              {featuredArticle && featuredArticle?.frontmatter?.image && (
                <div className="blog-page__banner__image animation-appear-left-slow animation-delay-2 grid-end-6 xs:grid-end-12 relative block">
                  <Link to={featuredArticle.fields.slug}>
                    <figure className="offgrid-right offgrid-right--no-touch">
                      <Img
                        className="w-full relative block"
                        fluid={
                          featuredArticle.frontmatter.image.childImageSharp
                            .fluid
                        }
                        alt="Tea in a pot"
                      />
                    </figure>
                  </Link>
                </div>
              )}
            </div>
          </section>
        )}

        <ArticleGrid articles={articles} />

        <Newsletter />
      </Layout>

      <Footer />
    </>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "article-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            featured
            priority
            tagline
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
