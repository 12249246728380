import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const ArticleGrid = ({ articles, max }) => {
  let renderedArticles = JSON.parse(JSON.stringify(articles));

  if (max) {
    renderedArticles = articles.slice(0, max);
  }

  //

  return (
    <section className="w-full relative pt-20 xs:pt-8 pb-24 xs:pb-0">
      {renderedArticles?.[0] && (
        <section className="w-full relative">
          <ul className="grid">
            {renderedArticles.map((article, articleIndex) => {
              const key = `article-${articleIndex}`;

              return (
                <li
                  key={key}
                  className="article-grid__item animation-appear-slow grid-end-4 sm:grid-end-6 xs:grid-end-12 relative block mb-32 xs:mb-16 xs:pb-12"
                >
                  <Link
                    className="article-grid__item__image relative block"
                    to={article.fields.slug}
                  >
                    {article?.frontmatter?.image?.childImageSharp && (
                      <Img
                        className="w-full relative block"
                        fluid={article.frontmatter.image.childImageSharp.fluid}
                        alt="Placeholder"
                      />
                    )}
                  </Link>

                  <h4 className="mt-8 caption">
                    {article.frontmatter.category}
                  </h4>

                  <h3 className="mt-3 f4">{article.frontmatter.title}</h3>
                  <p className="mt-3 b2">{article.frontmatter.tagline}</p>

                  <Link
                    to={article.fields.slug}
                    className="inline-block mt-8 b1 underline"
                  >
                    Read story
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      )}
    </section>
  );
};

ArticleGrid.defaultProps = {
  max: null
};

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  max: PropTypes.number
};

export default ArticleGrid;
